import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const RepairExperts = () => {
  return (
    <Layout>
      <SEO title="Superior Merchants" description="" />
      <div className="consulting-wrapper wrapper py-5">
        <div className="row">
          <div className="col-12">
            <Image src="superior-merchants.png" />
          </div>
        </div>
        <h1 className="mt-3">Superior Merchants</h1>
        <span>
          Superior Merchants help businesses to grow by providing them all
          services under one roof. Taking all the husstle out and connecting you
          with the experts in the field to save your time and resources.
        </span>
        <p>The following are the achievements we are always proud of.</p>
        <ul>
          <li>Huge number of merchants</li>
          <li>Nationwide supplier</li>
          <li>Quality driven services</li>
          <li>Best suitable for businesses</li>
        </ul>
        <p>
          Superior Merchants provide ease of access to different services. They
          can buy top quality from there with just a matter of clicks. Just
          follow simple steps and get best quotes without going anywhere.
        </p>
      </div>
    </Layout>
  )
}

export default RepairExperts
